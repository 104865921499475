import service from '@/middleware'
import { getDateTimeRange } from '@/tools/functions'
import enterpriseApi from '@/api/enterprise.api'
import { TimeRanges } from '@colven/common-domain-lib/lib'

const currentStatusReportApi = {
  async getReport (filters, from, route) {
    const result = await service.http.post('/api/current-status/deferred', { filters, from, route })
    return result
  },
  async getReportNotDeferred (types, activities, sector, from) {
    const result = await service.http.post('/api/current-status/not-deferred', { types, from, activities, sector })
    return result
  },
  async getChart (types, from, activities, sector) {
    const result = await service.http.post('/api/current-status/chart', { types, from, activities, sector })
    return result
  },
  async getDetail (id, from, typeKey) {
    const result = await service.http.get('/api/current-status/detail/' + id + '/' + typeKey + '/' + from)
    return result
  },
  async getFrom () {
    let from
    if (JSON.parse(localStorage.getItem('enterpriseTimeSpan'))) {
      from = getDateTimeRange(TimeRanges.ENTERPRISE, null, null, null, null, null).tFrom
    } else {
      const enterpriseTimeSpan = (await enterpriseApi.getEnterpriseCustomTimeSpan()).data
      if (enterpriseTimeSpan) {
        localStorage.setItem('enterpriseTimeSpan', JSON.stringify(enterpriseTimeSpan))
        from = getDateTimeRange(TimeRanges.ENTERPRISE, null, null, null, null, null).tFrom
      } else {
        from = 0
      }
    }
    return from
  }
}

export default currentStatusReportApi
