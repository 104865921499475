import { mapMutations, mapState, mapGetters } from 'vuex'
import deepcopy from 'deepcopy'
import AutocompleteComponent from '@/components/commons/autocomplete/AutocompleteComponent.vue'

export default {
  name: 'BreadcrumbComponent',
  components: {
    AutocompleteComponent
  },
  props: {
    /**
     * tab : {
     *  id: <identificador único del tab>,
     *  name: <nombre del tab>
     * }
     */
    tabs: {
      type: Array,
      required: true
    },
    tabSelected: {
      type: Function,
      required: true
    },
    buttons: {
      type: Array,
      required: false,
      default: () => { return [] }
    }
  },
  data () {
    return {
      selectedEntity: undefined
    }
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale

    const search = new URLSearchParams(window.location.search)

    const selectedtab = search.get('tab')

    if (selectedtab) {
      this.tabSelected(this.tabs[selectedtab - 1].id)
    }
  },
  computed: {
    ...mapState('app', {
      entitySelectorItems: state => state.entitySelectorItems,
      selectedItem: state => state.selectedItem,
      entitySelectorConfiguration: state => state.entitySelectorConfiguration
    }),
    entitySelectorItemsSorted () {
      const selectorItemsCopy = deepcopy(this.entitySelectorItems)
      const that = this
      return selectorItemsCopy.sort(function sortFunction (a, b) {
        const nameA = a[that.entitySelectorConfiguration.text] ? a[that.entitySelectorConfiguration.text].toLowerCase() : null
        const nameB = b[that.entitySelectorConfiguration.text] ? b[that.entitySelectorConfiguration.text].toLowerCase() : null
        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        } else {
          return 0
        }
      })
    },
    ...mapGetters({
      'details': 'breadcrumb/getDetails',
      'warning': 'breadcrumb/getWarning'
    })
  },
  created () {
    this.$store.watch(state => state.app.selectedItem, () => {
      if (this.$store.state.app.selectedItem) {
        this.selectedEntity = this.$store.state.app.selectedItem
      } else if (this.$store.state.app.entitySelectorItems && this.$store.state.app.entitySelectorItems.length > 0) {
        this.selectedEntity = this.$store.state.app.entitySelectorItems[0]
      }
    })
  },
  beforeDestroy () {
  },
  methods: {
    ...mapMutations('app', [
      'setSelectedItem'
    ]),
    selectedFunction (newValue) {
      this.selectedEntity = newValue && newValue[this.entitySelectorConfiguration.value] ? newValue : this.selectedEntity
    }
  },
  watch: {
    selectedEntity () {
      this.setSelectedItem(this.selectedEntity)
    }
  }
}
