import currentStatusReportService from '@/business/currentStatusReportService'
import { filterRulesMapped } from '@/tools/filterRules'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { dateSortFunction } from '@/tools/functions'
import service from '@/middleware'
import { configurationService } from '@/business/configurationService'
import { SnackbarStyle } from '@/constants/constants'
import i18n from '@/i18n'
import { clone } from 'ramda'
import { mapCurrentStateService } from '@/business/mapCurrentStateService'

export default {
  name: 'CurrentStatusReportDetail',
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: '_id',
    mapData: [],
    mapConfiguration: [],
    showZones: [],
    showPois: [],
    loadingTable: true,
    intervalId: null,
    middleware: service.http,
    configurationKey: 'machine-current-status-detail-report',
    tableConfig: {}
  }),
  computed: {
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    }),
    ...mapState('app', {
      entitySelectorItems: state => state.entitySelectorItems,
      selectedItem: state => state.selectedItem,
      entitySelectorConfiguration: state => state.entitySelectorConfiguration
    })
  },
  beforeDestroy () {
    this.closeReportDefer()
    this.resetEntitySelector()
    clearInterval(this.intervalId)
    this.intervalId = null
    this.setBreadcrumbDetails(null)
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    await this.getConfig()
    this.getData()
  },
  methods: {
    ...mapMutations('app', {
      resetEntitySelector: 'resetEntitySelector'
    }),
    ...mapActions('snackbar', {
      showSnackbar: 'showSnackbar',
      closeSnackbar: 'closeSnackbar'
    }),
    ...mapMutations('reportDefer', {
      commitVisible: 'commitVisible',
      closeReportDefer: 'closeReportDefer'
    }),
    ...mapMutations({
      'setBreadcrumbDetails': 'breadcrumb/commitDetails'
    }),
    refreshTable () {
      this.getData(true)
    },
    async getData (refresh = false) {
      if (this.selectedItem) {
        this.getDetail(this.selectedItem._id, this.selectedItem.type, refresh)
        if (this.selectedItem.autoReport) {
          const that = this
          clearInterval(this.intervalId)
          this.intervalId = setInterval(
            function () {
              if (that.selectedItem) {
                that.getDetail(that.selectedItem._id, that.selectedItem.type, true)
              }
            }, that.selectedItem.autoReportMinutes * 60 * 1000)
        }
      }
    },
    getDetail (id, type, refresh = false) {
      this.loadingTable = true
      if (this.model.loadingSpinner) this.commitVisible({ visible: true })
      currentStatusReportService.getDetail(id, type).then(response => {
        if (!refresh) {
          this.setTableFilterRules(response.headers)
          this.tableHeaders.cleanAndUpdate(response.headers)
          /*
          Para ordenar las columnas fecha y hora
          TO DO: analizar la posibilidad de incluir este tipo de sorting en el componente genérico
          */
          this.tableHeaders.find(header => header.value === 'positionTimestamp').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') }
          this.tableHeaders.find(header => header.value === 'comunicationTimestamp').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') }
        }

        this.tableData.cleanAndUpdate(response.data)
        this.mapData.cleanAndUpdate(clone(response.data))
        response.maps.showZones = this.showZones
        response.maps.showPois = this.showPois
        this.mapConfiguration = response.maps
        this.loadingTable = false
        if (this.model.loadingSpinner) this.commitVisible({ visible: false })
      })
    },
    setTableFilterRules (headers) {
      this.tableFilterRules = {}
      headers.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    dataChangeEventHandler () {
      // SE DESACTIVA ESTE MÉTODO PARA EVITAR LA NO VISUALIZACIÓN DEL MAPA
      // Al pasar por el paginador, se toma como un cambio y se actualizan los datos del mapa con sólo el texto de la tabla
      // Esto causa que se pierda el arreglo de posiciones (queda un string) y se rompa al graficar

      // if (JSON.stringify(this.mapData) !== JSON.stringify(newData)) {
      //   this.mapData.cleanAndUpdate(clone(newData))
      // }
    },
    /**
     * Para cargar la configuración de la tabla
     */
    async getConfig () {
      const config = await configurationService.get(this.configurationKey)
      this.tableConfig = config && config.data ? config.data : {}
    },
    /**
     * Para guardar la configuración de la tabla
     * @param {*} config
     */
    saveConfig (config) {
      configurationService.save(this.configurationKey, config)
        .then(() => {
          this.showSnackbar({ visible: true, text: i18n.t('user.configuration.saveSuccess'), timeout: 10000, style: SnackbarStyle.SUCCESS })
          this.getConfig()
        }).catch(() => {
          this.showSnackbar({ visible: true, text: i18n.t('user.configuration.saveError'), timeout: 10000, style: SnackbarStyle.ERROR })
        })
    },
    /**
     * Ejecuta una query a la base de datos indexada del browser para obtener un ícono personalizado
     * @param {*} id
     */
    async getCustomIcon (id) {
      const icon = await mapCurrentStateService.getCustomIcon(id)
      return icon != null ? icon : null
    },
    // Para implementar una vez se guarden las configuraciones de los filtros idMap, groupName, references, mapsId
    mapResetFilter () {
    },
    saveShowZonesAndPois (newZones, newPois) {
      this.showZones = newZones
      this.showPois = newPois
    }
  },
  watch: {
    selectedItem () {
      this.getData()
    }
  }
}
