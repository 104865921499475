import service from '@/middleware'

const reportStorageApi = {
  async getReport (key) {
    const result = await service.http.get(`/api/report/${key}`)
    // const result = {
    //   data: [],
    //   charts: [],
    //   maps: [],
    //   headers: []
    // }
    return result
  }
}

export default reportStorageApi
