import Vue from 'vue'
import currentStatusReportService from '@/business/currentStatusReportService'
import reportStorageApi from '@/api/report-storage.api'
import { filterRulesMapped } from '@/tools/filterRules'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { dateSortFunction } from '@/tools/functions'
import i18n from '@/i18n'
import activityApi from '@/api/activity.api'
import sectorApi from '@/api/sector.api'
import thingApi from '@/api/thing.api'
import { TimeRanges, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib'
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue'
import BreadcrumbComponent from '@/components/commons/breadcrumb/BreadcrumbComponent.vue'
import CurrentStatusReportDetail from '@/components/machine/current-status-report/detail/CurrentStatusReportDetail.vue'
import service from '@/middleware'
import { configurationService } from '@/business/configurationService'
import { SnackbarStyle } from '@/constants/constants'
import autoReportService from '@/business/autoReportService'
import { mapCurrentStateService } from '@/business/mapCurrentStateService'

export default {
  name: 'CurrentStatusReport',
  components: {
    SelectorComponent,
    BreadcrumbComponent,
    CurrentStatusReportDetail
  },
  model: {
    prop: 'model'
  },
  /**
   * MODELO
   *
   * sector: String. La clave del sector al que corresponde el reporte, para prefiltrar actividades y tipos de equipos
   *  --> Ejemplo: "FIELD_ENGINEERING"
   */
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: '_id',
    chartConfiguration: [],
    chartComponentModel: {},
    maps: [],
    showZones: [],
    showPois: [],
    loadingTable: false,
    showSelector: false,
    selectorModel: {
      showClose: true,
      filters: [
        {
          id: 'sector',
          name: i18n.t('headers.sector'),
          show: false,
          disabled: false,
          showDialog: false,
          singleSelect: true,
          data: [],
          selectedData: [],
          selectAction: undefined
        },
        {
          id: 'filter-activity',
          name: i18n.t('headers.activity'),
          show: false,
          disabled: true,
          showDialog: false,
          singleSelect: true,
          data: [],
          selectedData: [],
          selectAction: undefined
        },
        {
          id: 'filter-thing-type',
          name: i18n.t('headers.thingType'),
          show: false,
          disabled: true,
          showDialog: false,
          singleSelect: true,
          data: [],
          selectedData: [],
          selectAction: undefined
        }
      ],
      selects: [],
      disableGetReportButton: true,
      showDateAndTimeSelector: false,
      selectedDateAndTimeRange: TimeRanges.LAST_HOUR,
      selectedDateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
      sinceDate: null,
      sinceTime: null,
      toDate: null,
      toTime: null,
      customDateTimeValidForm: false,
      autoReport: false,
      autoReportMinutes: 1,
      autoReportName: null
    },
    settingDisabled: false,
    tabsCurrentStatus: [
      {
        id: 'tab-current-status-summary',
        name: i18n.t('dashboard.currentStatus')
      }
    ],
    tabSelectedCurrentStatus: 'tab-current-status-summary',
    breadcrumbButtomsCurrentStatus: [],
    loadingSpinner: true,
    reportDetailModel: {
      loadingSpinner: false
    },
    intervalId: null,
    disableRefreshTable: true,
    showNoChartData: false,
    middleware: service.http,
    configurationKey: 'machine-current-status-report',
    tableConfig: {},
    reportNameForChart: [{ id: 'reportName', name: `<b>${i18n.t('dashboard.currentStatus')}</b>` }],
    // back-up del modelo del selector
    sectors: [],
    activities: [],
    types: [],
    // minutos del auto-reporte
    autoReportMinutes: 1,
    // nombre del auto-reporte
    autoReportName: null,
    // id del auto-reporte que se está generando y guardando en la bandeja temporal de reportes
    autoReportId: null
  }),
  computed: {
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    })
  },
  created () {
    this.breadcrumbButtomsCurrentStatus.push(
      {
        id: 'setting-btn',
        icon: 'settings',
        show: () => { return true },
        disable: this.disableSelectorButton.bind(this),
        function: this.selector.bind(this)
      })
  },
  beforeDestroy () {
    this.closeReportDefer()
    clearInterval(this.intervalId)
    this.intervalId = null
    this.setBreadcrumbDetails(null)
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    await this.getConfig()
    // Inicializo los datos del selector
    await this.initializeSelector()
    // carga inicial del reporte
    this.initialReportLoad()
  },
  methods: {
    ...mapMutations({
      'setBreadcrumbDetails': 'breadcrumb/commitDetails',
      'showReportDefer': 'reportDefer/showReportDefer',
      'closeReportDefer': 'reportDefer/closeReportDefer',
      'commitVisible': 'reportDefer/commitVisible',
      'setEntitySelectorItems': 'app/setEntitySelectorItems',
      'setEntityConfiguration': 'app/setEntityConfiguration',
      'resetEntitySelector': 'app/resetEntitySelector',
      'setSelectedItem': 'app/setSelectedItem'
    }),
    ...mapActions({
      'setDetails': 'breadcrumb/setDetails',
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar',
      'setCurrentReportId': 'autoReport/setCurrentReportId'
    }),
    verifyDataField (field, data) {
      const records = []
      for (const record of data) {
        const fieldData = record[field]
        if (!fieldData) {
          record[field] = ''
        }
        records.push(record)
      }
      return records
    },
    /**
     * Incializador del selector
     */
    async initializeSelector () {
      // callback cuando se seleccionan items en los filtros
      this.selectorModel.filters[1].selectAction = this.activityFilterCallback.bind(this)
      this.selectorModel.filters[0].selectAction = this.sectorFilterCallback.bind(this)
      // datos para el selector de sectores
      const sectorResponse = await sectorApi.getAll()
      this.selectorModel.filters[0].data.cleanAndUpdate(sectorResponse.data)

      if (this.model.sector) {
        this.selectorModel.filters[0].selectedData = this.selectorModel.filters[0].data.filter(s => s.key === this.model.sector)
        this.selectorModel.filters[0].disabled = true
        this.selectorModel.filters[0].hide = true
        this.sectorFilterCallback()
      } else if (sectorResponse.data.length === 1 && sectorResponse.data[0].key === 'NO_SECTOR') {
        this.selectorModel.filters[0].selectedData = this.selectorModel.filters[0].data.filter(s => s.key === 'NO_SECTOR')
        this.selectorModel.filters[0].disabled = true
        this.selectorModel.filters[0].hide = true
        this.sectorFilterCallback()
      }
    },
    async sectorFilterCallback () {
      if (this.selectorModel.filters[0].selectedData.length > 0) {
        this.selectorModel.filters[1].selectedData = []
        this.selectorModel.filters[1].disabled = false
        this.selectorModel.filters[2].selectedData = []
        this.selectorModel.filters[2].disabled = true
        // datos para el selector de actividades
        const activitiesResponse = await activityApi.getAllForSelector(this.selectorModel.filters[0].selectedData[0].key)
        this.selectorModel.filters[1].data.cleanAndUpdate(activitiesResponse.data)
        if (activitiesResponse.data.length === 1 && activitiesResponse.data[0].key === 'NO_ACTIVITY') {
          this.selectorModel.filters[1].selectedData = this.selectorModel.filters[1].data.filter(s => s.key === 'NO_ACTIVITY')
          this.selectorModel.filters[1].disabled = true
          this.selectorModel.filters[1].hide = true
          this.activityFilterCallback()
        }
      } else {
        this.selectorModel.filters[1].selectedData = []
        this.selectorModel.filters[1].disabled = true
        this.selectorModel.filters[2].selectedData = []
        this.selectorModel.filters[2].disabled = true
      }
    },
    /**
     * Función que es ejecutada luego de seleccionar las actividades (callback)
     */
    async activityFilterCallback () {
      if (this.selectorModel.filters[1].selectedData.length > 0) {
        this.selectorModel.filters[2].selectedData = []
        this.selectorModel.filters[2].disabled = false
        const thingTypesResponse =
          await thingApi.getThingTypesByActivity(this.selectorModel.filters[1].selectedData.map(a => a.id), this.model.sector || this.selectorModel.filters[0].selectedData[0].key)
        this.selectorModel.filters[2].data.cleanAndUpdate(thingTypesResponse.data)
      } else {
        this.selectorModel.filters[2].selectedData = []
        this.selectorModel.filters[2].disabled = true
      }
    },
    /**
     * Para el botón del breadcrumb
     */
    selector () {
      this.showSelector = !this.showSelector
    },
    /**
     * Para deshabilitar el botón del breadcrumb
     */
    disableSelectorButton () {
      return this.settingDisabled
    },
    /**
     * TO DO
     * Acción del botón para actualizar los datos de la tabla
     */
    refreshTable () {
      if (this.loadingSpinner) this.commitVisible({ visible: true })
      this.loadingTable = true
      this.getDataNotDeferred(true)
    },
    /**
     * Generar reporte
     * @param {*} eventData
     */
    getReport (eventData) {
      this.loadingTable = true

      // back-up de selectores
      this.sectors.cleanAndUpdate(this.selectorModel.filters[0].selectedData)
      this.activities.cleanAndUpdate(this.selectorModel.filters[1].selectedData)
      this.types.cleanAndUpdate(this.selectorModel.filters[2].selectedData)
      this.autoReport = eventData.autoReport
      this.autoReportMinutes = eventData.autoReportMinutes
      this.autoReportName = eventData.autoReportName

      if (eventData.autoReport) {
        if (this.loadingSpinner) this.commitVisible({ visible: true })
        this.getDataNotDeferred()
        clearInterval(this.intervalId)
        const that = this
        this.intervalId = setInterval(
          function () {
            that.getDataNotDeferred(true)
          }, eventData.autoReportMinutes * 60 * 1000)
      } else {
        // filtros para generar el reporte
        const filters = {
          sector: this.selectorModel.filters[0].selectedData,
          activities: this.selectorModel.filters[1].selectedData,
          types: this.selectorModel.filters[2].selectedData
        }

        // generar el reporte diferido
        currentStatusReportService.getReport(filters, this.$route.path)
        this.showReportDefer({ updateFunction: this.getData.bind(this) })
      }
      this.showSelector = false
    },
    /**
     * Para obtener los datos del reporte diferido
     * @param {*} reportKey
     */
    getData (reportKey) {
      if (reportKey) {
        this.settingDisabled = false
        this.showSelector = false
        this.disableRefreshTable = true
        this.loadingTable = true
        if (this.loadingSpinner) this.commitVisible({ visible: true })
        reportStorageApi.getReport(reportKey).then(response => {
          this.setTableFilterRules(response.data.headers)
          this.tableHeaders.cleanAndUpdate(response.data.headers)
          /*
          Para ordenar las columnas fecha y hora
          TO DO: analizar la posibilidad de incluir este tipo de sorting en el componente genérico
          */
          this.tableHeaders.find(header => header.value === 'positionTimestamp').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') }
          this.tableHeaders.find(header => header.value === 'comunicationTimestamp').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') }
          this.tableData.cleanAndUpdate(this.verifyDataField('stateName', response.data.data))
          this.showNoChartData = this.tableData.length === 0
          this.chartConfiguration.cleanAndUpdate(response.data.charts)
          this.resetChart()
          response.data.maps.showZones = this.showZones
          response.data.maps.showPois = this.showPois
          this.maps = response.data.maps
          this.initializeSelectorData(response.data.filters.filters)
          this.loadingTable = false
          if (this.loadingSpinner) this.commitVisible({ visible: false })
          this.setReportDetails()
        })
      } else {
        this.showSelector = true
      }
    },
    async initializeSelectorData (filterData) {
      if (filterData) {
        // Filtro de sectores
        if (this.selectorModel.filters[0].data.length > 0 && filterData.sector && filterData.sector.length) {
          this.selectorModel.filters[0].selectedData.cleanAndUpdate(filterData.sector)
          await this.sectorFilterCallback()
        }

        // Filtro de actividades
        if (this.selectorModel.filters[1].data.length > 0 && filterData.activities && filterData.activities.length > 0) {
          this.selectorModel.filters[1].selectedData.cleanAndUpdate(filterData.activities)
          await this.activityFilterCallback()
        }

        // Filtro de tipos
        if (this.selectorModel.filters[2].data.length > 0 && filterData.types && filterData.types.length > 0) {
          this.selectorModel.filters[2].selectedData.cleanAndUpdate(filterData.types)
        }

        // auto-reporte
        if (filterData.autoReport != null) {
          this.selectorModel.autoReport = filterData.autoReport
        }
        if (filterData.autoReportMinutes != null) {
          this.selectorModel.autoReportMinutes = filterData.autoReportMinutes
        }
        this.selectorModel.autoReportName = null

        // back-up de datos del selector
        // sectores
        if (filterData.sector) {
          this.sectors.cleanAndUpdate(filterData.sector)
        } else {
          this.sectors = []
        }
        // actividades
        this.activities.cleanAndUpdate(filterData.activities)
        // tipos
        this.types.cleanAndUpdate(filterData.types)
        // auto-reporte
        this.autoReportMinutes = filterData.autoReportMinutes
        this.autoReportName = filterData.autoReportName

        this.setReportDetails()
      }
    },
    /**
     * Modo autoreporte, para obtener y actualizar los datos del reporte
     */
    getDataNotDeferred (refresh = false) {
      this.loadingTable = true

      // ruta y query params
      const route = this.$route.path
      const queryParams = this.$route.query

      if (this.loadingSpinner) this.commitVisible({ visible: true })
      currentStatusReportService.getAutoReport(
        this.types, this.activities, this.sectors, this.autoReportMinutes,
        this.autoReportName, refresh ? this.autoReportId : null, route, queryParams).then(response => {
        if (!refresh) {
          this.setTableFilterRules(response.data.headers)
          this.tableHeaders.cleanAndUpdate(response.data.headers)
          /*
          Para ordenar las columnas fecha y hora
          TO DO: analizar la posibilidad de incluir este tipo de sorting en el componente genérico
          */
          // this.tableHeaders.find(header => header.value === 'date').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY') }
          // this.tableHeaders.find(header => header.value === 'time').sort = (a, b) => { return dateSortFunction(a, b, 'HH:mm:ss') }
          this.tableHeaders.find(header => header.value === 'positionTimestamp').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') }
          this.tableHeaders.find(header => header.value === 'comunicationTimestamp').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') }
          this.setReportDetails()
          this.disableRefreshTable = false

          // id del reporte guardado en la bandeja temporal
          this.autoReportId = response.id
        }
        this.tableData.cleanAndUpdate(this.verifyDataField('stateName', response.data.data))
        this.showNoChartData = this.tableData.length === 0
        this.chartConfiguration.cleanAndUpdate(response.data.charts)
        this.resetChart()
        response.data.maps.showZones = this.showZones
        response.data.maps.showPois = this.showPois
        this.maps = response.data.maps
        this.loadingTable = false
        if (this.loadingSpinner) this.commitVisible({ visible: false })
      })
    },
    /**
     * Para configurar el detalle del reporte
     */
    setReportDetails () {
      const detailData = {
        activities: this.selectorModel.filters[1].selectedData,
        types: this.selectorModel.filters[2].selectedData,
        reportName: this.reportNameForChart
      }
      const dataStructure = [
        { detailDataProp: 'reportName', propTitle: this.$t('report'), propType: 'LIST', propRefs: { list: this.reportNameForChart, value: 'id', text: 'name' } },
        { detailDataProp: 'activities', propTitle: this.$t('activity'), propType: 'LIST', propRefs: { list: this.selectorModel.filters[1].selectedData, value: 'id', text: 'name' } },
        { detailDataProp: 'types', propTitle: this.$t('headers.thingType'), propType: 'LIST', propRefs: { list: this.selectorModel.filters[2].selectedData, value: 'id', text: 'name' } }
      ]
      this.setDetails({
        detailData,
        dataStructure
      })
    },
    /**
     * Para setear las reglas de filtrado
     * @param {*} headers
     */
    setTableFilterRules (headers) {
      headers.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    /**
     * Cambia de tab (breadcrumb)
     * @param {*} id
     */
    tabSelected (id) {
      const detailRef = this.$refs['current-status-report-detail']
      if (id === 'tab-current-status-summary') {
        this.commitVisible({ visible: false })
        this.reportDetailModel.loadingSpinner = false
        this.loadingSpinner = true
        this.$refs['currentStatusReportMap'].change()
      } else if (id === 'tab-current-status-detail' && detailRef) {
        this.commitVisible({ visible: false })
        this.reportDetailModel.loadingSpinner = true
        this.loadingSpinner = false
        if (detailRef.$refs['map-current-status-detail']) {
          detailRef.$refs['map-current-status-detail'].change()
        }
      }
      this.tabSelectedCurrentStatus = id
    },
    /**
     * Click en una fila de la tabla (ir al detalle)
     * Primero se carga el selector de dispositivos y luego se mueve al detalle
     * @param {*} data
     */
    summaryReportRowClick (data) {
      this.setEntityConfiguration({ name: this.$t('devices'), value: '_id', text: 'name' })
      const items = []
      this.tableData.forEach(thing => {
        items.push({
          _id: thing._id,
          name: thing.name,
          type: thing.type,
          autoReport: this.selectorModel.autoReport,
          autoReportMinutes: this.selectorModel.autoReportMinutes
        })
      })
      this.setEntitySelectorItems(items)
      this.setSelectedItem({
        _id: data._id,
        name: data.name,
        type: data.type,
        autoReport: this.selectorModel.autoReport,
        autoReportMinutes: this.selectorModel.autoReportMinutes
      })
      if (!this.tabsCurrentStatus.find(tab => tab.id === 'tab-current-status-detail')) {
        this.tabsCurrentStatus.push({
          id: 'tab-current-status-detail',
          name: i18n.t('detail')
        })
      }
      this.commitVisible({ visible: false })
      this.reportDetailModel.loadingSpinner = true
      this.loadingSpinner = false
      this.tabSelectedCurrentStatus = 'tab-current-status-detail'
    },
    validateSelector () {
      const that = this
      Vue.nextTick(function () {
        that.selectorModel.disableGetReportButton = that.selectorModel.filters[1].selectedData.length === 0 || that.selectorModel.filters[2].selectedData.length === 0
      })
    },
    /**
     * Para cargar la configuración de la tabla
     */
    async getConfig () {
      const config = await configurationService.get(this.configurationKey)
      this.tableConfig = config && config.data ? config.data : {}
    },
    /**
     * Para guardar la configuración de la tabla
     * @param {*} config
     */
    saveConfig (config) {
      configurationService.save(this.configurationKey, config)
        .then(() => {
          this.showSnackbar({ visible: true, text: i18n.t('user.configuration.saveSuccess'), timeout: 10000, style: SnackbarStyle.SUCCESS })
          this.getConfig()
        }).catch(() => {
          this.showSnackbar({ visible: true, text: i18n.t('user.configuration.saveError'), timeout: 10000, style: SnackbarStyle.ERROR })
        })
    },
    /**
     * Reset chart
     */
    resetChart () {
      if (this.$refs['currentStatusChart']) this.$refs['currentStatusChart'].resetAll()
    },
    /**
     * Carga inicial del reporte, este método se ejecuta en el hook mounted para decidir
     * si el reporte debe cargarse de la base de datos (diferido) o es un autoreporte
     */
    initialReportLoad () {
      // obtener los datos de la url
      const queryValidation = autoReportService.autoReportRouteQueryValidation(this.$route.query)
      switch (queryValidation.type) {
        // diferido
        case 'DEFERRED':
          this.getData(queryValidation.key)
          break
        // auto-reporte
        case 'AUTO_REPORT':
          this.getAutoReportData(queryValidation.autoReportId)
          break
        // reporte en blanco
        case 'EMPTY':
          this.showSelector = true
          break
        default:
          this.showSelector = true
          break
      }
    },
    /**
     * Obtener datos del autoreporte y cargarlo
     * @param {*} reportId
     */
    async getAutoReportData (reportId) {
      this.loadingTable = true
      this.showSelector = false
      this.disableSelector = false
      this.disableRefreshTable = false
      clearInterval(this.intervalId)

      const report = await autoReportService.getReportFromDB(reportId)
      if (report != null) {
        // id del autoreporte
        this.autoReportId = report.id
        // datos de los filtros
        await this.initializeSelectorData(report.filters)

        // datos del reporte
        this.setTableFilterRules(report.data.headers)
        this.tableHeaders.cleanAndUpdate(report.data.headers)
        /*
        Para ordenar las columnas fecha y hora
        TO DO: analizar la posibilidad de incluir este tipo de sorting en el componente genérico
        */
        // this.tableHeaders.find(header => header.value === 'date').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY') }
        // this.tableHeaders.find(header => header.value === 'time').sort = (a, b) => { return dateSortFunction(a, b, 'HH:mm:ss') }
        this.tableHeaders.find(header => header.value === 'positionTimestamp').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') }
        this.tableHeaders.find(header => header.value === 'comunicationTimestamp').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') }
        this.setReportDetails()

        this.tableData.cleanAndUpdate(this.verifyDataField('stateName', report.data.data))
        this.showNoChartData = this.tableData.length === 0
        this.chartConfiguration.cleanAndUpdate(report.data.charts)
        this.resetChart()
        report.data.maps.showZones = this.showZones
        report.data.maps.showPois = this.showPois
        this.maps.cleanAndUpdate(report.data.maps)

        // lanzo la actualización
        const that = this
        this.intervalId = setInterval(
          function () {
            that.getDataNotDeferred(true)
          }, that.autoReportMinutes * 60 * 1000)
      } else {
        this.showSelector = true
      }
      this.loadingTable = false
    },
    /**
     * Ejecuta una query a la base de datos indexada del browser para obtener un ícono personalizado
     * @param {*} id
     */
    async getCustomIcon (id) {
      const icon = await mapCurrentStateService.getCustomIcon(id)
      return icon != null ? icon : null
    },
    // Para implementar una vez se guarden las configuraciones de los filtros (idMap, groupName, references, mapsId)
    mapResetFilter () {
      // this.mapConfiguration = Object.assign({}, this.mapConfigOrigin)
    },
    saveShowZonesAndPois (newZones, newPois) {
      this.showZones = newZones
      this.showPois = newPois
    }
  },
  watch: {
    'selectorModel.filters': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    }
  }
}
