import currentStatusReportApi from '@/api/currentStatusReport.api'
import enterpriseApi from '@/api/enterprise.api'
import { getDateTimeRange } from '@/tools/functions'
import { TimeRanges } from '@colven/common-domain-lib/lib'
import autoReportService from './autoReportService'
import i18n from '@/i18n'

// respuesta por defecto cuando se hace una llamada de api y falla
const DEFAULT_RESPONSE = {
  headers: [],
  data: [],
  charts: [],
  maps: []
}

/**
 * Generar reporte diferido
 * @param {*} filters
 * @param {*} route
 * @returns
 */
const getReport = async (filters, route) => {
  try {
    const response = await currentStatusReportApi.getReport(filters, 0, route)
    return response.data
  } catch (e) {
    return null
  }
}

/**
 * Generar reporte no diferido
 * @param {*} types
 * @param {*} activities
 * @param {*} sector
 * @returns
 */
const getReportNotDeferred = async (types, activities, sector) => {
  try {
    const response = await currentStatusReportApi.getReportNotDeferred(types, activities, sector, 0)
    return response.data
  } catch (e) {
    return DEFAULT_RESPONSE
  }
}

/**
 * Autoreporte
 * @param {*} types
 * @param {*} activities
 * @param {*} sectors
 * @param {*} autoReportMinutes
 * @param {*} autoReportName
 * @param {*} autoReportId
 * @param {*} route
 * @param {*} queryParams
 * @returns
 */
const getAutoReport = async (
  types, activities, sectors, autoReportMinutes,
  autoReportName, autoReportId, route, queryParams) => {
  try {
    const sector = sectors[0] ? sectors : null
    const response = await currentStatusReportApi.getReportNotDeferred(types, activities, sector, 0)

    const filters = {
      sector: sectors,
      activities,
      types,
      autoReport: true,
      autoReportMinutes,
      autoReportName
    }
    const id = await autoReportService.saveAutoReport(response.data, filters, route, i18n.t('machines.reports.currentStatus'), autoReportName, autoReportId)

    // cambiar query params de ser necesario
    autoReportService.updateQueryParams(route, queryParams, id)

    return { id, data: response.data }
  } catch (e) {
    return { id: null, data: DEFAULT_RESPONSE }
  }
}

const getChart = async (types, activities, sector) => {
  try {
    const response = await currentStatusReportApi.getChart(types, 0, activities, sector)
    return response.data
  } catch (e) {
    return []
  }
}

const getDetail = async (id, typeKey) => {
  try {
    const from = await getFrom()
    const response = await currentStatusReportApi.getDetail(id, from, typeKey)
    return response.data
  } catch (e) {
    return DEFAULT_RESPONSE
  }
}

const getFrom = async () => {
  try {
    let from
    if (JSON.parse(localStorage.getItem('enterpriseTimeSpan'))) {
      from = getDateTimeRange(TimeRanges.ENTERPRISE, null, null, null, null, null).tFrom
    } else {
      const enterpriseTimeSpan = (await enterpriseApi.getEnterpriseCustomTimeSpan()).data
      if (enterpriseTimeSpan) {
        localStorage.setItem('enterpriseTimeSpan', JSON.stringify(enterpriseTimeSpan))
        from = getDateTimeRange(TimeRanges.ENTERPRISE, null, null, null, null, null).tFrom
      } else {
        from = 0
      }
    }
    return from
  } catch (e) {
    return 0
  }
}

export default {
  getReport,
  getReportNotDeferred,
  getChart,
  getDetail,
  getFrom,
  getAutoReport
}
