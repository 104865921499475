import service from '@/middleware'

class ActivityApi {

  async getAllForSelector(sector = null, report = null) {
    return service.http.get(`/api/activity/activity-selector`, { params: { sector, report } });
  }

  async getAll() {
    return service.http.get('/api/activity/activity-selector');
  }
}

export default new ActivityApi();
