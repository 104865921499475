import service from '@/middleware'

const sectorApi = {
  async getAll () {
    let result = await service.http.get('/api/sector')
    return result
  },
  async getByKey (key) {
    let result = await service.http.get('/api/sector/key/' + key)
    return result
  }
}

export default sectorApi
