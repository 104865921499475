export const filterRules = {
  stringFilter: (str1, str2) => {
    if (!str2) {
      return true
    } else if (str1) {
      return (str1.toString().toLowerCase()).includes(str2.toString().toLowerCase())
    }
  },
  arrayFilter: (arr1, arr2) => {
    if (!arr2) {
      return true
    } else if (arr1 && arr1.length > 0) {
      return (arr1.toString().toLowerCase()).includes(arr2.toString().toLowerCase())
    } else {
      return false
    }
  },
  optionsFilter: (str, arrStr) => {
    if (!arrStr || arrStr.length === 0) {
      return true
    } else if (str) {
      return arrStr.includes(str.toString())
    } else {
      return false
    }
  }
}

export const filterRulesMapped = {
  TEXT_FIELD: filterRules.stringFilter,
  SELECT: filterRules.optionsFilter,
  MULTISELECT: filterRules.optionsFilter
}
